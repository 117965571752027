*{
    margin:0px;
    padding: 0px;
    font-family: "Baskervville SC", serif;
  font-weight: 400;
  font-style: normal;

}

.head{
    height:100px;
    width:100%;
    background-color: black;
    color:white;
display: flex;
justify-content: center;
align-items: center;
}
.input{
    width:40%;
}
.icons{
    display: flex;
    flex-direction: row;
    gap:20px;
}
.icons i{
    font-size: 20px;
}
.icon{
    height: 100%;
    width:100%;
    display: flex;
    background-color:black;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:30px;
}
.icon1{
    height:150px;
    width:150px;
    /* background-color: aqua; */
     border-radius: 20px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border:2px solid rgb(241, 219, 219);
     box-sizing: border-box;
     margin:5% 0px;
}
.icon1:hover{
background-color: rgb(31, 29, 29);

}
.icon1:hover.icon1 img{
transform: scale(1.3);
transition:  all 0.4s;
}
.navbb{
    width:500px;
}
/* styles.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.navbar {
    /* background-color: #333; */
    color: white;
    padding: 10px 20px;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.hamburger {
    display: flex; /* Show hamburger menu on all screens */
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    border-radius: 2px;
    transition: background-color 0.3s ease;
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none; /* Hide nav links by default */
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on header height */
    left: 0;
    width: 100%;
    background-color: #333;
}


.nav-links.active {
    display: flex; /* Show nav links when active */
    position: absolute;
    z-index: 2;
}

.nav-links li {
    margin: 0;
}

.nav-links a {
    padding: 10px 20px;
    display: block;
    color: white;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 1px solid #444;
}

.nav-links a:hover {
    text-decoration: underline;
}

.nav-links a:last-child {
    border-bottom: none;
}

.top{
    margin-top: 20px;
    height:100%;
    width:100%;

}
.top1{
   
    display:flex;
    gap:30px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.budget{
    height:100%;
    width:100%;
 margin-top: 10%;
  
}
.budget1{
    height: 100%;
    width:100%;
    display: flex;
    gap:30px;
    justify-content: center;
    /* align-items: center; */
    flex-wrap: wrap;
}
.under,.under1,.under2,.under3{
    height:150px;
    width:280px;
   border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.featured{
    height:100%;
    width:100%;
    /* border:2px solid red; */
    margin-top: 10%;

}
.feat1{
margin-top: 3%;
    display: flex;
    gap:35px;
    justify-content: center;
    flex-wrap: wrap;
    
}
.feat2,.feat3,.feat4,.feat5{
    height:280px;
    width:280px;
    /* background-color: rgb(187, 184, 184); */
    /* border:2px solid black; */
    border-radius: 10px;
box-sizing: border-box;
box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);

}
.feat6:hover.feat6 img{
    transform: scale(0.7);
    transition: all 0.5s;
}

.declub,.contact,.quick,.follow{
    height:200px;
    width:270px;
}
.follow i{
    font-size: 20px;
}
.sale{
    height:100%;
    width:100%;
    background-color: rgb(10, 9, 9);
    color:white;
}
.sale1{
    display: flex;
    justify-content: space-evenly;
    margin-top: 5%;
    align-items: center;
    flex-wrap: wrap;
}
.sale2 img{
    height: 300px;
}
@media (max-width:400px){
    .sale2 img{
        height: 180px;
    }
    .input{
        display: none;
    }
    .salee{
      display: none;
    }
}
.salee{
    height: 80px;
    width:130px;
    border:2px solid rgb(131, 71, 71);
    padding:10px;
    transform:skew(10deg);
    border-radius:10px;
    background-color: white;
    color:red;

}
.footer{
    height:100%;
    width:100%;
    background-color: #333;
    color:white;
}
.footer1{
    /* height:500px; */
    margin-top: 5%;
}
.footer2{
    height:200px;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:20px;
    flex-wrap: wrap;
}
.icon11 i{
    font-size: 25px;
    padding-left: 15px;
    height: 50px;
    width:50px;
    margin:5px;
    text-align: center;
   padding: 10px 5px;
    border-radius: 50%;
    background-color: rgb(83, 84, 85);
}
.icon11 i:hover{
    background-color: rgb(127, 153, 238);
}
.footer3{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100%;
}
.company,.products,.contact{
    width:300px;
}
.car{
    position: relative;
}
.cn1{
    position: relative;
  
}
.carous1{
    position: relative;
}
.contimg{
    position: relative;
}
.content{
    position: absolute;
    z-index: 5;

    color:white;
    z-index: 3;
    height:300px;
    width:60%;
    top:30%;
    left:20%;
    text-align: center;

}

/* .carous1{
    background: url("https://www.globalrootsindia.com/images/test-bg-70.jpg") center/cover no-repeat fixed;
    height:500px;
    
  
} */
/* .meo ul{
    display: none;
}
.meo2{
    display: block;
} */
/* src/Navbar.css */
/* Hide dropdowns by default */
.dropdown-menu {
    display: none;          
}

/* Show dropdowns when parent is active */
.dropdown-menu.show {
    display: block;                   
}
 /* Show nested dropdowns on hover */
.dropdown-submenu:hover .dropdown-menu {
    display: block;                                       
    position: absolute;
    left: 100%;
    top: 0;
}
.about{
height:100%;
width:100%;
}
.abou{
    height: 100%;
    width:100%;
    display: flex;
    gap:10%;
    flex-direction: column;
}
.about1{
    height:100%;
    width:100%;
    margin: 5% 5% 0% 5%;
    display: flex;
    gap:40px;
    flex-wrap: wrap;

}
.abt1{
    height: 400px ;
    width:600px;
    margin-top: 5%;
}

.about2{
   display: flex;
   flex-wrap: wrap;
    height:100%;
    width:100%;
 
    justify-content: center;
    gap:5%;
}
.ab1 ,.ab2{
    height:300px;
    width:500px;
}
@media (max-width:950px){
    .abt2{
        height: 300px;
    }
    .abt2 img{
        height:250px;
    }
    .abt1{
        height:250px;
    }
   
}
.services{
    height: 100%;
    width:100%;
    background-color:beige;
    color:rgb(24, 22, 22);
}
.ser1{
 height: 150px;
    width:300px;
    margin-top: 3%;

}
.ser1 strong{
    color:blue;
}
.service1{
    height: 100%;
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:30px;
}
.contact1{
    height:100%;
    width: 100%;
}

.contact3{
    height:100%;
    width:100%;
     margin-top: 5%;
}
.contact4{
    margin-top: 5%;
    display: flex;
    gap:20px;
    justify-content: center;
    flex-wrap: wrap;
}
.contact6{
    margin-top: 5%;
display: flex;
gap:30px;
width:100%;
height:100%;
margin-left: 20px;
justify-content: center;
flex-wrap: wrap;
}
.contac{
    height:100px;
    width:500px;
}
.contact2{
    height:500px;
    /* background: url("../images/Cont\ \(1\).jpg") center/cover no-repeat fixed; */
    width:100%;
}

.laptop{
    height: 100%;
    width: 100%;
}
.laptop2{
    height:100%;
    width:100%;
    display: flex;
    justify-content: center;
    gap:30px;
    flex-wrap: wrap;
}
.laptop1{
    height:370px;
    width:350px;
border-radius: 10px;
box-shadow: 5px 5px 5px rgb(218, 190, 190);
}

.laptop1 img{
    height:300px;
    width:300px;
}
.contact7{

height: 500px;
width:100%;
}
.contact7{
    
   position: relative;

/* padding-top: 5%; */

border-radius: 5px;
box-shadow: 5px 5px 5px rgb(66, 64, 64);
width:500px;
}
.formm{
    display: flex;
    gap:30px; 
   height:400px;
padding-left: 20px;
  flex-wrap: wrap;
  width:500px;

}
.form1 input{
   border:none;
   border-bottom: 1px solid black;
}
.inter1{
    height: 100%;
    width:100%;
}
.card1{
    border-radius: 10px;

   box-shadow: 5px 5px 5px rgb(155, 149, 149);
}
.inter2{
    margin-top: 5%;
    height: 100%;
    width:100%;
    display: flex;
    gap:30px;
    justify-content: center;
    flex-wrap: wrap;
 
}
.card:hover img{
    transform: scale(0.9);
    transition: all 0.3s;
}
.comm2{
    display: flex;
    flex-wrap: wrap;
    gap:30px;
    justify-content: center;
    padding-top: 5%;
}
.desk1{
    height:100%;
    width:100%;
}
.desk2{
    height:100%;
    width:100%;
    display: flex;
    justify-content: center;
    gap:30px;
    flex-wrap: wrap;
  
}
.laptop{
    height:100%;
    width:100%;
}
.in{
    height:100%;
    width:100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}
.in2{
   height:100%;
   width:600px;


 
}

.in3{
    height:100px;
    width:500px;
}
.inn{
    height:100%;
    width:100%;

}
/* .in4{
    display: flex;
    flex-direction: row;
    justify-content: center;
} */
 .sale3 h1{
    font-size: 20px;
 }
 .cc img{
    width:500px;
 }
 @media (max-width:650px){
.carrr{
    width:100%
}
    .icons{
        display: none;
    }
    .input{
        display: none;
    }
    .in2{
        height:100%;
        width: 200px;
    }
    .in{
        justify-content: start;
    }
    .card-body{
        width:50%;
    }
    .in3{
        height:100%;
        width:70%;
    }
    .salee{
        display: none;
    }
  
    .sale3 h1{
        font-size: 15px;;
    }
 }
@media (max-width:920px){
    .sale3{
        margin-bottom: 10px;
    }
}
.contimg img{
    height: 400px;
}
@media (max-width:500px){
    .carousel-item{
        height: 500px;
  
    }
    .contimg img{
        height:500px;
    }
    .content p{
        font-size: 10px;
    }
    .content h4{
        font-size: 15px;
    }
    .content h3{
        font-size: 20px;
    }
    .footer h2{
        font-size: 18px;
    }
    .icon11 i{
        font-size: 15px;
        height:30px;
        width: 30px;
    }
    .company h2,.products h5,.contact h5{
        font-size: 15px;
        text-align: center;
    }
    .products p,.contact p{
        font-size: 10px;
    }
   
}
.ccimg{
    width:400px;
}
@media (max-width:640px){
   /* .in2{
    height:300px;
    overflow: hidden;
   } */
    .ccimg{
        width:250px;
        height:200px;
    }
    .cch{
        font-size: 12px;
    }
    .cct{
        font-size: 10px;
    }
    .footer{
        text-align: left;
    }
    .card-body{
        width:100%;
    }
    .contact5 iframe{
        border:2px solid rgb(209, 116, 116);
        width:400px;
    }
    .contact5{
        width:400px;
    }

     .ab1 h1,.abt2 h1,.ab2 h1{
        font-size: 20px;
     }
     .ab1 p,.abt2 p,.ab2 p{
        font-size: 15px;
     }
     .about2{
        margin:20px;
     }
     .about1{
        margin:10px;
        
     }
     .abt1{
        height:400px;
     }
}
@media (max-width:350px){

    .company h2{
        font-size: 10px;
    }
    .products h5{
        font-size: 8px;
    }
    .footer2 h2{
        font-size: 8px;
    }
    .contact h5{
        font-size: 10px;
    }
    .footer2{
        height: 100px;
    }
    /* .lapimg{
        height:200px;
        width:200px;
    } */
}
.card1{
    height:400px;
    width:400px;
}
.comm2 img,.peri img{
height: 250px;
width:350px
}
@media (max-width:500px){
    .laptop1 img{
        height:200px;
        width:200px;
    }
    .laptop1{
        width:300px;
        height: 250px;
        text-align: center;
    }
    .laptop2{
        justify-content: start;
    }
    .deskimg{
        width:290px;
        height:300px;
    }
 .card1{
    height: 400px;
    width:300px;
 }
 .comm2 img,.peri img{
    height:200px;
    width:300px;
 }
 .contac h5{
    font-size: 15px;
 }
 .contac p{
    font-size: 10px;
 }
}
@media (max-width:350px){
    .comm2 img,.peri img{
        width:230px;
        height:180px;
    }
    .contac h5{
        font-size: 10px;
    }
    .conatact6{
        gap:20px
    }
}
@media (max-width:500px){
    .formm{
        display: flex;
        flex-direction: column;
        height:500px;
    }
}

/* .form1 button{
    margin-top: 10px;
} */
 .subject input{
    width:400px;
 }
@media (max-width:500px){
    .contimg img{
        height:300px;
    }
    .car{
        height:320px;
    }
    .logo{
        font-size: 15px;
    }
    .form1 input{
        width:170px;
    }
    .contact7{
        width:350px;
        height:650px;
        box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2), 10px 0 15px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
    }
    .formm{
        justify-content: center;
        align-items: center;
    }
    .subject input{
        width:200px;
    }
    .formm{
        height:650px;
        width:400px;
    }
    .contact6{
        width:300px;
    }
    .contac{
        width:200px;
    }
  
}
@media(max-width:380px){
    .contimg img{
        height: 350px;
    }
    .car{
        height:400px;
    }
    .under,.under1,.under2,.under3{
        width:200px;
        height:80px;
    }
    .under h4,.under1 h4,.under2 h4,.under3 h4{
        font-size: 15px;
    }
    .under p,.under1 p,.under2 p,.under3 p{
        font-size: 10px;
    }
    .budget h3{
        font-size: 20px;
    }
 .top1 img{
    width:100px;
    height:70px;
 }
 .icon1{
    height:100px;
    
    width:150px;
 }
 .icon1 img{
    height:60px;
    width:70px;
 }
 .carousel-item img{
    height:400px;
 }
.carousel{
    height:400px;
}
.logo{
    font-size: 15px;
}
}
@media (max-width:300px){
    .icon1{
        height: 80px;
        width:100px
    }
    .icon1 h5{
        font-size: 10px;
    }
}
.contact2{
    position: relative;
}
.conth{
   position:absolute;
   top:25%;
   z-index: 1;

}
@media (max-width:400px){
    .conth h1{
        font-size: 15px;
    }
}
.icon11 a{
    text-decoration: none;
}
