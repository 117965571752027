#root{
    margin:0px;
    padding: 0px;
}
*{
    margin:0px;
    padding: 0px;

}
html,body{
    margin:0px;
    padding: 0px;
    overflow-x: hidden;
}